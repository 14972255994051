var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    {
      staticClass: "pa-5 d-flex flex-column",
      attrs: {
        outlined: true,
        color: "white",
        rounded: true,
        width: "100%",
        "max-width": "500px",
      },
    },
    [
      _c("div", { staticClass: "mt-2 mb-10 mew-heading-3 text-center" }, [
        _vm._v(" Please enter the password of your "),
        _c("span", { staticClass: "text-capitalize" }, [
          _vm._v(_vm._s(_vm.walletType)),
        ]),
        _vm._v(" device. "),
      ]),
      _c("mew-input", {
        attrs: {
          label: "Password",
          placeholder: "Enter your password",
          type: _vm.onCoolWallet ? "text" : "password",
        },
        model: {
          value: _vm.password,
          callback: function ($$v) {
            _vm.password = $$v
          },
          expression: "password",
        },
      }),
      _c("mew-checkbox", {
        staticClass: "justify-center",
        attrs: { label: "To access my wallet, I accept ", link: _vm.link },
        model: {
          value: _vm.acceptTerms,
          callback: function ($$v) {
            _vm.acceptTerms = $$v
          },
          expression: "acceptTerms",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }